import { Content } from "rsuite"
import React, { useEffect, useRef } from 'react';
import $ from "jquery";
export const VideoPlayer = () => {
    const videoRef = useRef(null);
    const divRef = useRef(null);
  
    useEffect(() => {
      function handleResize() {
        const videoPlayer = $('#video_player')[0]; // Select the video player using jQuery
        const divElement = $(divRef.current)[0]; // Select the div using jQuery
    
        if (videoPlayer && divElement) {
          videoPlayer.playbackRate = 1.5;
          videoPlayer.style.height = `${window.innerHeight}px`;
          divElement.style.height = `${window.innerHeight}px`;
        }
      }
    
      window.addEventListener('resize', handleResize);
      handleResize();
    
      return () => window.removeEventListener('resize', handleResize);
    }, []);


    return(
    <Content className="w-full h-full">
       
       
        <div dangerouslySetInnerHTML={{ __html: `
        <video  id="video_player" class="home-videoplayer translate-y-4.5 md:brightness-100 brightness-50 w-full h-full" autoPlay
        muted
        loop
        playsInline>
            <source src="site_vid1.mp4" type="video/mp4" />
            <source src="site_vid1.webm" type="video/webm"  />
            Your browser does not support the video tag.
        </video>
        ` }}></div>
         
          <h1 className="md:hidden block z font-extralight hero scratched glitch">
            WE <br /> MAKE
            <br /> GAMES
          </h1>
            {/* <p className="z hero  glitch">
            WE 
            <><br />&nbsp;&nbsp;</>
            CREATE 
            <><br /> </>
            <span className="ml-[230px] " > &nbsp;&nbsp;&nbsp;&nbsp;GAMES</span>
        </p>    */}
        <div ref={divRef} className=" bg-main"></div>
        <div className="vidbar overflow-hidden w-full h-[200px] bg-main  z-20">
            <div className=" rot w-[500vh] h-[100%] mt-10 flex justify-start items-start">
            </div>
        </div>
    </Content>
    );
}