import { Container, Content } from "rsuite";
import AppFooter from "./footer";
import './glitch.css';
import { HomeContent } from "./home_content";
import { VideoPlayer } from "./Video_player";
import { Header } from "./header";
import { useTranslation } from "react-i18next";
 
export const Home = () => {
    const isMobile = window.innerWidth <= 600;
    
    const { t, ready  } = useTranslation('EN');
    console.log(t('home.about'))

    return( 
        <Container> 
            <VideoPlayer></VideoPlayer>
            <HomeContent></HomeContent> 
        </Container>

    ); 
}