import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import { CustomProvider } from 'rsuite';

const backendOptions = {
  loadPath: '/lang/{{lng}}.json',
  crossDomain: true,
};

i18next
  .use(XHR)
  .init({
    backend: backendOptions,
    lng: "EN",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <I18nextProvider i18n={i18next}>
    <CustomProvider theme='dark'>
      <App />
    </CustomProvider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();