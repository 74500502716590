import { useEffect, useRef, useState } from "react";
import { BiLink, BiShare } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";
import { MdShare } from "react-icons/md";
import { TiHeart, TiHeartOutline } from "react-icons/ti";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container } from "rsuite";
import { Button, Panel, Placeholder } from "rsuite";
import $ from "jquery"; 
import _ from 'lodash';
import Cookies from 'js-cookie'; 
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

export const Devlog = () => {
    const [page] = useState(sessionStorage.getItem("read_devlog")); 
    let { title } = useParams();
    const [blog, SetBlog] = useState(); 
    const [liked, SetLiked] = useState(false); 
    const [tempLikes, SetTempLikes] = useState(); 
    // console.log(document.cookie);
    const navigate = useNavigate(); 
    const [hasLiked, setHasLiked] = useState(false); 

    const LikeClick = (e) => {
        SetLiked(prevLiked => !prevLiked); 
        
    };
    const [copyLink, SetCopyLink] = useState("Copy link"); 
    const isFirstRender = useRef(true); // Reference to track the first render


    function OnCopyLink(e){
        e.preventDefault(); 
        SetCopyLink("Copied to clipboard!");
        navigator.clipboard.writeText(window.location.href);
        setTimeout(() => {
            
            SetCopyLink("Copy link");
        }, 3000);
    }
    // This useEffect runs only once when the component is mounted
    useEffect(() => {
        if(isFirstRender){
            window.scrollTo(0, 0);
        
            const data = new FormData();
            data.append('blogs', page); // Use p instead of page
            data.append('fetch', title); // Use p instead of page

            fetch('https://api.zwlsoftware.nl/autoxize/', {
                method: 'POST',
                body: data
            })
            .then(response => response.json())
            .then(data => {
                if (data?.err !== 1) {
                    // Success
                    SetBlog(data['data']);
                    SetTempLikes(data['data'].likes);

                } else {
                    console.log("error");
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
        
        const cookieName = `autoxize.com-liked-${page}`; // Create a unique cookie name for each blog post
        const alreadyLiked = Cookies.get(cookieName); // Check if the user has already liked the post
        
        if (alreadyLiked) {
            SetLiked(true); // Set the liked state to true if the user has already liked the post
            setHasLiked(true);
            $('#likebtn').addClass("liked"); // Add the "liked" class to the button
        }
    }, []);
    
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; // It's no longer the first render
            return; // Don't send a request on the first render
        }
        const debouncedSave = _.debounce(() => sendLikeRequest(), 250); // Debounce the function by 1000ms
    
        if(liked){
            $('#likebtn').addClass("liked");
            SetTempLikes(tempLikes + 1);
            debouncedSave(); // Call the debounced function
        }
        else
        {
            $('#likebtn').removeClass("liked");
            SetTempLikes(tempLikes - 1);
            debouncedSave(); // Call the debounced function
        }
    
        return () => { debouncedSave.cancel() }; // Cancel the debounced function when the component is unmounted
    }, [liked]);
    
        
    const sendLikeRequest = () => {
        const cookieName = `autoxize.com-liked-${page}`; // Create a unique cookie name for each blog post
        const alreadyLiked = Cookies.get(cookieName); // Check if the user has already liked the post

        if (liked) {
            if (!alreadyLiked) {
                // The user has not liked the post before, so send a like request
                Cookies.set(cookieName, 'true', { expires: 36500 }); // Set the cookie to expire in 100 years
                SetTempLikes(tempLikes + 1); // Update the likes here
                setHasLiked(true); // Update the hasLiked state
                sendRequest('like');
            }
            // If the user has already liked the post, do nothing
        } else {
            // The user wants to unlike the post
            if (hasLiked) { // Only send an 'unlike' request if a 'like' request has been sent
                Cookies.remove(cookieName); // Remove the cookie
                SetTempLikes(tempLikes - 1); // Update the likes here
                setHasLiked(false); // Update the hasLiked state
                sendRequest('unlike');
            }
        }
    }
    
    const sendRequest = (action) => {
        const urlcode = 'autoxize.com';
        const timestamp = Date.now().toString();
        const nonce = uuidv4(); // Generate a unique nonce for each request
        const message = page + timestamp + nonce;
        const hash = CryptoJS.HmacMD5(message, urlcode).toString();
    
        const data = new FormData();
        data.append('blogs', page); // Use p instead of page
        data.append(action, page); // Use p instead of page
        data.append('timestamp', timestamp); // Add the timestamp
        data.append('nonce', nonce); // Add the nonce
        data.append('hash', hash); // Add the hash
    
        fetch('https://api.zwlsoftware.nl/autoxize/', {
            method: 'POST',
            body: data
        })
        .then(response => response.json())
        .then(data => {
            if (data?.err !== 1 && data['data']) {
                // Success and data['data'] is not empty
                SetBlog(data['data']);
            } else {
                console.log("error");
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }
    const renderLinks = (text) => {
        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        // Replace URLs with anchor tags
        return text.replace(urlRegex, (url) => `<span>  <a href="${url}" target="_blank">${url}</a></span>`);
      };
      
    return(<> 
       
 
        <div className="min-h-[80vh]"> 
            <div className="min-h-[100px]"></div>
            <Container className="flex justify-center items-center ">
                <div className="cCardc my-10 mb-40 md:w-[50%] w-[95%]"> 
                    <Panel>
                        <div className="flex flex-wrap items-center p-3 min-h-[300px]">
                            {blog && ( 
                                <div className=" w-full  ">
                                    <p className="md:hidden block text-start w-[100%] text-gray-500 mb-2 text-xs ">{blog.posted}</p>                            

                                    <div className="flex justify-start items-center gap-3">
                                        <p className="block md:text-end   my-2 text-xs flex gap-2 items-center justify-start "><FaRegEye />  {blog.views}</p>                            
                                        <p className="block md:text-end    mb-2 text-xs flex gap-2 items-center justify-start "> <TiHeartOutline /> {tempLikes}</p> 
                                        <p className="hidden md:block md:text-end w-[100%] text-gray-500 mb-2 text-xs ">{blog.posted}</p>                            

                                    </div>

                                    <div className="md:flex justify-start items-start">
                                    <h1 className="JikiTourai w-[100%] mb-5">{blog.title}</h1>


                                    </div>
                                    <p className="w-full mb-10"  style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: renderLinks(blog.blog)  }}></p>
  

                                    
                                </div>
                            )}
                            <div className="md:flex w-full gap-3">
                                <Tooltip id="my-tooltip" /> 
                                 
                                <Button onClick={LikeClick} id="likebtn" className="button-like mb w-[60px] mr-1">  <TiHeart className=" likeicon" /> </Button>
                                 
                               
                                <a data-tooltip-id="my-tooltip" data-tooltip-content={copyLink}> <Button className="button-share w-[60px]" onClick={OnCopyLink}> <MdShare className=" shareicon" /> </Button> </a>
                                <div className="md:hidden block h-[20px] "></div>
                                <Link to={'/devlogs'} className="w-[100%] nodec flex justify-end items-center"> <Button className="button-main w-[100%]  md:w-[200px]">Return</Button></Link>
                            </div>

                          
                        </div>
                    </Panel>
                </div> 
            </Container>
        </div> 
    </>); 

}