import logo from './logo.svg';
import './App.css';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { Home } from './home';
import { AppAds } from './app-ads';
import 'rsuite/dist/rsuite.min.css';
import './css/main.min.css';
import './css/fonts.min.css';
import { Blogs } from './Blogs';
import { Header } from './header';
import AppFooter from './footer';
import { Devlogs } from './Devlogs';
import { useState, useEffect   } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Devlog } from './Devlog';
import { AppPrivacy } from './AppPrivacy';

function MainRoutes() {
  const location = useLocation();
  const [scrollHide, setScrollHide] = useState(false); 
  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname === '/') {
      setScrollHide(false);
    } else {
      setScrollHide(true);
    }

    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
    
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    
    });
    
  }, [location.pathname]);

  return (
    <>
      {location.pathname !== "/app-ads.txt"  && <Header scrollhide={scrollHide} />}

      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/devlogs/" element={<Devlogs />} />
        <Route path="/devlog/:title" element={<Devlog />} />
        <Route path="/app-ads.txt" element={<AppAds />} />
        <Route path="/mobile/privacy-policy" element={<AppPrivacy />} />
      </Routes>

      {location.pathname !== "/app-ads.txt" && <AppFooter />}
    </>
  );
}

export default function App() {
  return (
    <Router basename='/'>
      <MainRoutes />
    </Router>
  );
}