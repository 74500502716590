import { Button, Panel, Placeholder } from "rsuite";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiMessageRoundedDots } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";
import { TiHeartFullOutline } from "react-icons/ti";

export const Devlogs = () => {
    const location = useLocation();
    const [blogs, setBlogs] = useState()
    const [page, SetPage] = useState(); 
    const [readPage, SetReadPage] = useState(); 
    const navigate = useNavigate();
    const [totalPages, SetTotalPages] = useState(); 
  
    

    function GoPage(p) {
        SetPage(p);
        // You might not need to return anything from GoPage
    }

    useEffect(() => {
        window.scrollTo(0, 0);
            
        const data = new FormData();
        data.append('blogs', page); // Use p instead of page

        fetch('https://api.zwlsoftware.nl/autoxize/', {
            method: 'POST',
            body: data
        })
        .then(response => response.json())
        .then(data => {
            if (data !== 0) {
                // Success
                setBlogs(data['data']);
                SetTotalPages(Math.ceil(data['blogs'] / 5));

            } else {
                console.log("error");
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    }, [page]); // Set p as the dependency

    const pages = [];
    for (let i = 0; i < totalPages; i++) {
        pages.push(
            <a href="#" onClick={() => SetPage(i * 5)}>
                <span className="mx-2 link"> {i  + 1} </span>
            </a>
        );
    }
    useEffect(() => {
        console.log(page);
    }, [page]);
    // useEffect(() => {
        
        
    //     const data = new FormData();
    //     data.append('blogs', 0);

    //     fetch('https://api.zwlsoftware.nl/autoxize/', {
    //         method: 'POST',
    //         body: data
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data != 0) {
    //                 //success
    //                 setBlogs(data['data']);
    //             }
    //             else {
    //                 console.log("error");
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error:', error);
    //         });

    // }, [location.pathname]);

    useEffect(()=> {});
    function GetIcon(O)
    {
        if(O === "update")
        {
            return <span className="text-red-500"> <MdOutlineTipsAndUpdates /> </span>; 
        }
        if(O == "message")
        {
            return <span className="text-blue-500"> <BiMessageRoundedDots /> </span>; 
        }
    }


    function ReadPage(p)
    {
        SetReadPage(p); 
        sessionStorage.setItem("read_devlog", p);
    }
    useEffect(() => {

        if (blogs !== undefined && blogs !== null)
            blogs.map((blog) => {
                if(blog.id === readPage){
                    // console.log(blog.title);
                    navigate("/devlog/"+  encodeURIComponent(blog.title).replace(/%20/g, '-').toLowerCase()+"/");
                }
            });
    }, [readPage, blogs]); 
    
    return (<>
        <div className="w-full min-h-[80vh] p-2  md:p-5">
            <div className="w-full h-[145px]" />
            <div className="flex justify-center items-center">
                <div className="flex flex-wrap w-[95%] md:w-[50%] gap-5">
                    {blogs && blogs.map((blog, index) => (
                        <div id={blog.id} className="w-full">
                            <div className="cCard " data-aos="fade-up"
                                data-aos-offset="-900"
                                data-aos-delay={300 * index}
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out"
                                data-aos-once="false"
                                data-aos-anchor-placement="bottom-center">
                                <Panel header={<> <span className="dv flex justify-start items-center gap-3"
                                > {GetIcon(blog.type)} <div className="flex items-center justify-start md:gap-4 w-full"><span className="text-2xl w-[100%]  ">{blog.title}</span>   <p className="hidden md:block md:text-end w-[100%] text-gray-500"> {new Date(new Date(blog.posted).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().slice(0,16).replace("T", " ")} </p> </div> </span> </>} shaded>
                                    <div className="flex flex-wrap gap-4">
                                        <div className="w-full">
                                            <p> { blog.blog.length > 165 ? blog.blog.substring(0, 165).trim() + "..." : blog.blog } </p>
                                        </div>
                                        <div className="w-full">
                                            <div className="flex gap-3 justify-start items-center">
                                                <span className="views md:hidden block"><p className="flex justify-start md:hidden block items-center gap-2"> <FaRegEye /> {blog.views}</p></span>
                                                <span className="views md:hidden block"><p className="flex justify-start items-center gap-2"> <TiHeartFullOutline /> {blog.likes}</p></span>
                                            </div>
                                            <p className="md:text-end w-[100%] mt-3 text-gray-500 mb-2 text-xs md:hidden block">{new Date(new Date(blog.posted).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().slice(0,16).replace("T", " ")}</p>                            
                                            <div className="w-[100%] md:mt-10 flex justify-start items-end ">
                                                <Link to={'/devlogs'} className="w-[100%] "> <Button  onClick={() => ReadPage(blog.id)} className="button-main w-[100%]  md:w-[200px]">Read</Button></Link>
                                                <div className="flex gap-3 justify-start items-center">
                                                    <span className="views md:block hidden"><p className="flex justify-start items-center gap-2"> <FaRegEye /> {blog.views}</p></span>
                                                    <span className="views md:block hidden"><p className="flex justify-start items-center gap-2"> <TiHeartFullOutline /> {blog.likes}</p></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                        </div>
                    ))}

                    <div className="w-full text-center">
                            <div>
                                {pages}
                            </div>
                    </div>
                </div>
            </div>

        </div>
    </>);
}