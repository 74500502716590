import { useEffect, useRef, useState } from "react";


export const AppPrivacy = () => {
 
    return (
      <>
        <div className="w-full h-[90vh] md:flex justify-center items-center">
          <div className="w-full h-full md:w-[80%] py-40 px-4 overflow-y-scroll no-scrollbar">
              <div id="privacy-policy" dangerouslySetInnerHTML={{ __html: `
          <html>

          <head>
              <meta content="text/html; charset=UTF-8" http-equiv="content-type">
          </head>
          
          <body class="c6 doc-content">
              <p class="c1"><span class="c10">Privacy Policy</span></p>
              <p class="c1"><span class="c11">Effective Date: February 7, 2024</span></p>
              <p class="c1"><span class="c2">This Privacy Policy describes how Autoxize (&quot;we,&quot; &quot;us,&quot; or
                      &quot;our&quot;) collects, uses, and shares personal information obtained through our mobile applications
                      (the &quot;Service&quot;).</span></p>
              <h3 class="c3" id="h.9yafs0duudpe"><span class="c4">1. Compliance Checklist</span></h3>
              <h4 class="c7" id="h.nwij4jcs4in"><span class="c8">1.1 Accessibility</span></h4>
              <ul class="c9 lst-kix_7yftf3wh1ert-0 start">
                  <li class="c0 li-bullet-0"><span class="c2">Our Privacy Policy is linked on our app&rsquo;s store listing page
                          in Play Console and is accessible within the app.</span></li>
                  <li class="c0 li-bullet-0"><span class="c2">The privacy policy is labeled clearly as a privacy policy.</span>
                  </li>
                  <li class="c0 li-bullet-0"><span class="c2">It is readable in a standard browser without any plug-ins or special
                          handlers (no PDFs).</span></li>
                  <li class="c0 li-bullet-0"><span class="c2">The privacy policy is available on an active, publicly accessible,
                          and non-geofenced URL.</span></li>
                  <li class="c0 li-bullet-0"><span class="c2">The privacy policy is non-editable.</span></li>
              </ul>
              <h4 class="c7" id="h.sccuxo56vmbk"><span class="c8">1.2 Entity Information</span></h4>
              <ul class="c9 lst-kix_hpxq47vej6mb-0 start">
                  <li class="c0 li-bullet-0"><span class="c2">Our privacy policy holds reference to Autoxize, the entity named in
                          the app&rsquo;s Google Play listing.</span></li>
              </ul>
              <h4 class="c7" id="h.x5zj3stflp20"><span class="c8">1.3 Contact Information</span></h4>
              <ul class="c9 lst-kix_hcsxmjmeg1e2-0 start">
                  <li class="c0 li-bullet-0"><span class="c2">We provide a privacy point of contact via email at
                          support@zwlsoftware.com for inquiries.</span></li>
              </ul>
              <h4 class="c7" id="h.motyypsm0djn"><span class="c8">1.4 Content</span></h4>
              <ul class="c9 lst-kix_hzj8ue15pkpp-0 start">
                  <li class="c0 li-bullet-0"><span class="c2">The privacy policy comprehensively discloses how our app accesses,
                          collects, uses, and shares user data.</span></li>
                  <li class="c0 li-bullet-0"><span class="c2">It discloses the types of personal and sensitive user data collected
                          and any parties with which the data is shared.</span></li>
                  <li class="c0 li-bullet-0"><span class="c2">Secure data handling procedures for personal and sensitive user data
                          are outlined.</span></li>
                  <li class="c0 li-bullet-0"><span class="c2">Our data retention and deletion policy is disclosed.</span></li>
              </ul>
              <h3 class="c3" id="h.u5rzpmwijwxt"><span class="c4">2. Third-Party Services</span></h3>
              <p class="c1"><span class="c2">We may employ third-party companies and individuals for the following
                      purposes:</span></p>
              <ul class="c9 lst-kix_a5nkt7z5k809-0 start">
                  <li class="c0 li-bullet-0"><span class="c2">To facilitate our Service.</span></li>
                  <li class="c0 li-bullet-0"><span class="c2">To provide the Service on our behalf.</span></li>
                  <li class="c0 li-bullet-0"><span class="c2">To perform Service-related services.</span></li>
                  <li class="c0 li-bullet-0"><span class="c2">To assist us in analyzing how our Service is used.</span></li>
              </ul>
              <p class="c1"><span class="c2">These third parties may have access to your Personal Information for the sole purpose
                      of performing tasks assigned to them on our behalf. However, they are obligated not to disclose or use the
                      information for any other purpose.</span></p>
              <h3 class="c3" id="h.fq5poe5gozba"><span class="c4">3. Security</span></h3>
              <p class="c1"><span class="c2">We value your trust and strive to use commercially acceptable means to protect your
                      Personal Information. However, please be aware that no method of transmission over the internet or
                      electronic storage is 100% secure, and we cannot guarantee absolute security.</span></p>
              <h3 class="c3" id="h.tjewyhe0s71y"><span class="c4">4. Links to Other Sites</span></h3>
              <p class="c1"><span class="c2">Our services may include links to the store listing or developer page inside Google
                      Play. We have no responsibility for the content, privacy policies, or practices of any third-party sites or
                      services.</span></p>
              <h3 class="c3" id="h.a8l4irc2cq57"><span class="c4">5. Children&rsquo;s Privacy</span></h3>
              <p class="c1"><span class="c2">Our services do not address anyone under the age of 13. We do not knowingly collect
                      personally identifiable information from children under 13. If we discover that a child under 13 has
                      provided personal information, we will immediately delete it from our servers. If you are a parent or
                      guardian and are aware that your child has provided us with personal information, please contact us at
                      support@zwlsoftware.com.</span></p>
              <h3 class="c3" id="h.nn8k8egslnzb"><span class="c4">6. Changes to This Privacy Policy</span></h3>
              <p class="c1"><span class="c2">We may update this Privacy Policy from time to time. Please review this page
                      periodically for any changes. The last update date is provided on the last page, and changes are effective
                      immediately after they are updated.</span></p>
              <h3 class="c3" id="h.n8co8fcb5rj8"><span class="c4">7. In&ndash;App Purchases</span></h3>
              <p class="c1"><span class="c2">Games or apps from Autoxize may contain in-app purchasing. Users can purchase items
                      or additional content within our games or apps. Refunds for paid, but not received or lost, products will
                      only be processed with evidence of purchase from Autoxize, including order ID, name, and date of the order.
                      Users can send an email to support@zwlsoftware.com for assistance.</span></p>
              <h3 class="c3" id="h.mv1jeowfrqd2"><span class="c4">8. Contact</span></h3>
              <p class="c1"><span class="c2">If you have any questions or suggestions about our Privacy Policy, do not hesitate to
                      contact Autoxize at support@zwlsoftware.com. Any spam or SDK offerings will be blocked.</span></p>
              <p class="c1 c5"><span class="c2"></span></p>
              <p class="c1 c5"><span class="c2"></span></p>
              <p class="c1 c5"><span class="c2"></span></p>
          </body>
          
          </html>
        ` }} /> 
        </div>
        </div>
      </>
    );
  };