import React, { useState, useEffect } from 'react';
import $ from 'jquery'; 
import { Link } from 'react-router-dom';
import { Button } from 'rsuite';
import { CgMenuRight } from "react-icons/cg";
import { HiMenu } from "react-icons/hi";
export const Header = ({scrollhide = false}) => {
    const [lastScrollPos, setLastScrollPos] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      const headerElement = document.getElementById('header');
      const headerElement2 = document.getElementById('header2');

      if(scrollhide && headerElement && headerElement2) {
          headerElement.style.opacity = 1;
      }
    }, [scrollhide]);

    const checkScroll = () => {

      
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        const headerElement = document.getElementById('header');
        const headerElement2 = document.getElementById('header2');
        if (scrollPosition > windowHeight * 0.2 && scrollPosition > lastScrollPos) {
          headerElement.classList.add('show');
          headerElement2.classList.remove('hidden');
        } else if (scrollPosition > windowHeight * 0.2 && scrollPosition < lastScrollPos) {
          headerElement.classList.add('show');
          headerElement2.classList.remove('hidden');
        } else if (scrollPosition < windowHeight * 0.2) {
          headerElement.classList.remove('show');
          headerElement2.classList.add('hidden');
        }
        setLastScrollPos(scrollPosition);
      };
    
  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, [lastScrollPos]);

  return (
    <>
   
    <div id="header" className="header w-full h-[auto] logo">
      <div className='m-4 mb-5 flex-wrap flex  md:justify-between justify-center gap-2 md:gap-4'>
      
        <a href='/' className='w-auto'> <img src='/autoxize-logo-transparant.png' ></img></a>

        <div className="w-[50vw] flex-nowrap sm:flex hidden justify-end items-center gap-10 SometypeMono ">
          <a href='/' className='w-auto'> <span>Home</span> </a>
          <a href='/devlogs/' className='w-auto'> <span>Blogs</span> </a>
          <a href='/' className='w-auto'> <span></span> </a>
          <a href='/' className='w-auto'> <span></span> </a>
        </div>

        
        <div className={`mobilenav md:hidden w-full flex flex-wrap md:justify-start justify-center items-center gap-10 SometypeMono mr-20 ${isOpen ? 'open pt-2' : ''}`}>
          <a href='/' className='w-full'> <span>Home</span> </a>
          <a href='/devlogs/' className='w-full'> <span>Blogs</span> </a>
        </div>


      </div>
    </div>
    <div id='header2' class="md:hidden hidden fixed bottom-0 right-0 m-6 w-16 h-16 bg-blue-500 rounded-full z-50">
        <div className='w-full h-full flex justify-center items-center'   onClick={() => setIsOpen(!isOpen)}>
          <div 
                className={`md:hidden block  flex justify-center items-center w-full menuicon ${isOpen ? 'rotate' : ''}`} 
              
              >
            <HiMenu />
          </div>
        </div>
    </div>
    </>
  );
}
