import React from 'react';
import { Container, Content, Footer, Col, Icon, Grid, Row, IconButton } from 'rsuite';
import { BiLogoInstagram, BiLogoPlayStore, BiLogoYoutube } from 'react-icons/bi';
const AppFooter = () => {
  return (
    <>
        <div className="vidbar flip overflow-hidden w-full h-[150px] bg-main  z-20">
            <div className=" rot w-[500vh] h-[100%] mt-10 flex justify-start items-start" style={{transform:"translateY(12px)"}}>
            </div>
        </div>
   
    <Container className='footer min-h-[300px] p-4'>

      <Footer>
        {/* <Grid fluid className='h-[100%]'>
          <Row className="show-grid h-[100%] w-full my-10">
            <Col xs={24} md={12} className=' min-h-[200px] w-full'>
  
                <div className='h-full w-full flex justify-center item-center'>
                    <div>
                        <img src='autoxize_w.png' height={180} width={180}></img>      
                        
                    </div>
                </div>    
            </Col>
            <Col xs={24} md={12}>
                 <div className='item-link'><a> - blog </a></div>
                <div className='item-link'><a> - Contact </a></div> 
        
            </Col>
          </Row>
        </Grid> */}
        <div className='h-full w-full flex justify-center item-center my-5'>
              <div>
                {/* <img src='autoxize_w.png' height={180} width={180}></img>       */}
                
            </div>
        </div> 
        <div className='flex justify-center items-center'>
          <div className='w-[75vw] opacity-40 overflow-hidden'>
            <hr></hr>
          </div>
        </div>
        <div className='links my-4'>
                <div  className='social-link'>
                    <a href="https://www.instagram.com/autoxize" className='flex color justify-center items-center' target="_blank" rel="noopener noreferrer">
                        <BiLogoInstagram></BiLogoInstagram>
                    </a>
                </div>
                <div  className='social-link'>
                    <a href="https://play.google.com" className='flex color justify-center items-center' target="_blank" rel="noopener noreferrer">
                        <BiLogoPlayStore></BiLogoPlayStore>
                    </a>
                </div>
                <div  className='social-link'>
                    <a href="https://www.youtube.com/@zwlsoftware7120" className='flex color justify-center items-center' target="_blank" rel="noopener noreferrer">
                        <BiLogoYoutube></BiLogoYoutube>
                    </a>
                </div>
            </div>
        <div className='text-white text-center'>
            Autoxize Games | Powered by <a href='https://zwlsoftware.com' target='_blank' className='text-white'>ZWL Software</a>
        </div>
      </Footer>
    </Container>
    </>
  );
}

export default AppFooter;
