import { useEffect, useState } from 'react';
export const AppAds = () => {
    const [data, setData] = useState('');

    useEffect(() => {
        fetch('/app-ads.txt')
          .then(response => response.text())
          .then(data => {
            setData(data);
          });
      }, []);
      return (
        <pre>{data}</pre>
      );

}